import React from 'react';
import Navigator from './views/Navigator';
import 'react-native-gesture-handler';
const App = () => {
  return (
    <>
      <Navigator />
    </>
  );
};

export default App;
