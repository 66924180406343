import axios from 'axios';

export const login = async (user_id, password) => {
  const ret = await axios.post('http://3.38.193.180:3030/login', {
    user_id: user_id,
    password: password,
  });
  console.log(ret.data);
  const loginChk = ret.data.status === 'success';
  const isAdmin = loginChk && ret.data.data.ADMIN === 1;
  const token = loginChk ? ret.data.data.token : null;
  return [loginChk, isAdmin, token];
};

export const verify_token = async token => {
  const ret = await axios.post('http://3.38.193.180:3030/verify_token', {
    token: token,
  });
  const code = ret.data.data.code;
  const user_id = ret.data.user_id;
  return [code, user_id];
};
