import {useIsFocused} from '@react-navigation/native';
import React, {useState, useEffect} from 'react';
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
  Pressable,
  Image,
  Linking,
} from 'react-native';
import {getSomeBoardItems} from '../../api/useContent';
import {loadAsync} from 'expo-font';

const getContent = async (navigation, searchValue) => {
  const contents = await getSomeBoardItems();
  const boardList = [];
  for (const content of contents) {
    if (
      searchValue.length >= 2 &&
      !content.TITLE.includes(searchValue) &&
      !content.CONTENT.includes(searchValue)
    ) {
      continue;
    }
    const pressMotion = () => {
      if (content.URL) {
        Linking.openURL(content.URL);
      }
    };
    boardList.push(
      <Pressable onPress={pressMotion}>
        <View style={styles.container.blank} />
        <View style={styles.container.container}>
          <Image style={styles.container.container.image} />
          <View style={styles.container.container.text}>
            <Text style={styles.container.container.text.category}>
              {content.CATEGORY}
            </Text>
            <Text
              style={styles.container.container.text.title}
              key={`${content.CREATED_AT}_${content.TITLE}`}>
              {content.TITLE}
            </Text>
            <Text
              style={styles.container.container.text.content}
              key={`${content.CREATED_AT}_${content.TITLE}_2`}
              numberOfLines={2}>
              {content.CONTENT}
            </Text>
          </View>
        </View>
      </Pressable>,
    );
  }
  return boardList;
};

const Content = ({navigation, searchValue}) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const isFocused = useIsFocused();
  const [boardListView, setBoardListView] = useState([]);
  useEffect(() => {
    const getFonts = async () => {
      await loadAsync({
        'SUIT-Regular' : require('../../assets/fonts/SUIT-Regular.otf'),
        'SUIT-Bold' : require('../../assets/fonts/SUIT-Bold.otf'),
        'SUIT-Medium' : require('../../assets/fonts/SUIT-Medium.otf'),
      });
      setFontsLoaded(true);
    }
    const get = async () => {
      const _boardListView = await getContent(navigation, searchValue);
      setBoardListView(_boardListView);
    };
    if (isFocused) {
      get();
    }
    if (!fontsLoaded) {
      getFonts();
    }
  }, [isFocused, navigation, searchValue]);
  return fontsLoaded ? (
    <>
      <ScrollView style={styles.scroll}>
        <View style={styles.container}>{boardListView}</View>
      </ScrollView>
    </>
  ) : <></>;
};

const styles = StyleSheet.create({
  scroll: {
    width: '100%',
  },
  container: {
    flexDirection: 'column',
    width: 346,
    marginTop: 7,
    marginLeft: 22,
    blank: {
      height: 24,
    },
    container: {
      flexDirection: 'row',
      image: {
        width: 118,
        height: 118,
        backgroundColor: '#000000',
      },
      text: {
        marginLeft: 22,
        marginTop: 32,
        width: 206,
        height: 78,
        category: {
          fontSize: 12,
          fontWeight: '700',
          color: '#EC5757',
          height: 15,
          fontFamily: 'SUIT-Bold',
        },
        title: {
          fontSize: 18,
          fontWeight: '500',
          height: 32,
          lineHeight: 25,
          fontFamily: 'SUIT-Regular',
        },
        content: {
          fontSize: 12,
          fontWeight: '400',
          color: '#8A8A8A',
          fontFamily: 'SUIT-Medium',
        },
      },
    },
  },
});

export default Content;
