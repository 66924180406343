import React, {useState, useEffect} from 'react';
import {Button, SafeAreaView, StyleSheet, Text, Pressable} from 'react-native';
import Content from '../components/home/Content';
import TopBanner from '../components/TopBanner';
import MainImg from '../components/home/MainImg';

const Home = ({navigation}) => {
  const onPress = () => {
    navigation.push('Admin');
  }
  return (
    <>
      <MainImg navigation={navigation} />
      <Content
        style={styles.content}
        navigation={navigation}
        searchValue={''}
      />
      <Pressable onPress={onPress}>
        <Text
          style={styles.admin}>
          관리자 페이지
        </Text>
      </Pressable>
    </>
  );
};

const styles = StyleSheet.create({
  admin: {
    width: '100%',
    height: 32,
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 32,
  },
  writeButton: {
    color: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    text: {
      color: '#000000',
      fontWeight: '700',
      fontSize: 36,
    },
    data: {
      color: '#FFFFFF',
    },
  },
  container: {
    flex: 1,
    backgroundColor: '#000000',
    alignItems: 'center',
  },
  content: {
    width: '100%',
  },
});

export default Home;
