import React, {useState, useEffect, useCallback} from 'react';
import {Button, SafeAreaView, StyleSheet, Text, Pressable, View, Image, TextInput} from 'react-native';
import Content from '../components/home/Content';
import {loadAsync} from 'expo-font';

const Search = ({navigation}) => {
  const [searchValue, setSearchValue] = useState('');
  const goBack = () => {
    navigation.pop();
  };
  const [fontsLoaded, setFontsLoaded] = useState(false);
  useState(() => {
    const getFont = async () => {
      await loadAsync({
        'NanumSquareNeo-Bold' : require('../assets/fonts/NanumSquareNeoOTF-cBd.otf'),

      });
      setFontsLoaded(true);
    }
    if (!fontsLoaded) {
      getFont();
    }
  }, []);
  return fontsLoaded ? (
    <>
      <SafeAreaView>
        <View style={styles.searchView}>
          <Pressable onPress={goBack}>
            <Image
              style={styles.searchView.prev}
              source={require('../assets/img/left_arrow.png')}
            />
          </Pressable>
          <TextInput
            style={styles.searchView.search}
            placeholder={'검색어를 입력해주세요'}
            maxLength={20}
            value={searchValue}
            onChangeText={e => setSearchValue(e)}
            placeholderTextColor="#000000"
            autoCapitalize="none"
            autoCorrect={false}
          />
        </View>
        <View style={styles.line} />
        <Content
          style={styles.content}
          navigation={navigation}
          searchValue={searchValue}
        />
      </SafeAreaView>
    </>
  ) : <></>;
};

const styles = StyleSheet.create({
  line: {
    width: '100%',
    height: 1,
    backgroundColor: '#EAE9E9',
  },
  searchView: {
    width: 390,
    height: 57,
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    prev: {
      width: 48,
      height: 48,
    },
    search: {
      marginLeft: 3,
      fontSize: 14,
      fontWeight: '700',
      fontFamily: 'NanumSquareNeo-Bold',
    },
  },
  container: {
    flex: 1,
    backgroundColor: '#000000',
    alignItems: 'center',
  },
  content: {
    width: '100%',
  },
});

export default Search;
