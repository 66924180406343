import {React, useState} from 'react';
import {View, Text, StyleSheet, TextInput, Button, Platform, Image} from 'react-native';
import {useMediaLibraryPermissions, launchImageLibraryAsync, MediaTypeOptions} from 'expo-image-picker';

const Admin = () => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [content, setContent] = useState('');
  const [contentURL, setContentURL] = useState('');
  const [photo, setPhoto] = useState('');

  const dataURItoBlob = dataURI => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], {type: mimeString});
    console.log(blob);
    return blob;
  }

  const handleChoosePhoto = async () => {
    const ret = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
      aspect: [1,1],
    });
    if (ret.canceled) {
      return null;
    }
    setPhoto(ret.assets[0].uri);
    console.log(ret.assets[0].uri);
    dataURItoBlob(ret.assets[0].uri);
  };

  return(
    <>
      <View style={styles.container}>
        <Text style={styles.text}>제목</Text>
        <TextInput
            placeholder="제목을 입력해주세요."
            style={styles.input.title}
            maxLength={20}
            value={title}
            onChangeText={e => setTitle(e)}
            autoCapitalize="none"
            autoCorrect={false}
        />
        <Text style={styles.text}>카테고리</Text>
        <TextInput
            placeholder="카테고리를 입력해주세요."
            style={styles.input.category}
            maxLength={20}
            value={category}
            onChangeText={e => setCategory(e)}
            autoCapitalize="none"
        />
        <Text style={styles.text}>내용</Text>
        <TextInput
            placeholder="내용을 입력해주세요."
            style={styles.input.content}
            maxLength={20}
            value={content}
            onChangeText={e => setContent(e)}
            autoCapitalize="none"
        />
        <Text style={styles.text}>URL</Text>
        <TextInput
            placeholder="URL을 입력해주세요."
            style={styles.input.url}
            maxLength={20}
            value={contentURL}
            onChangeText={e => setContentURL(e)}
            autoCapitalize="none"
        />
        <Text style={styles.text}>이미지</Text>
        <Button title={'첨부하기'} onPress={handleChoosePhoto}/>
        <Image
          style={styles.image}
          source={{uri: photo}}
        />
      </View>
    </>
  )
}

export default Admin;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    color: '#000000',
    fontSize: 24,
  },
  input: {
    title: {
      width: '80%',
      color: '#000000',
      backgroundColor: '#CCCCCC',
      fontSize: 24,
      textAlign: 'center',
      borderRadius: 10,
      marginVertical: 10,
    },
    content: {
      width: '80%',
      height: '50%',
      color: '#000000',
      backgroundColor: '#CCCCCC',
      fontSize: 24,
      textAlign: 'center',
      borderRadius: 10,
      marginVertical: 10,
    },
    url: {
      width: '80%',
      color: '#000000',
      backgroundColor: '#CCCCCC',
      fontSize: 24,
      textAlign: 'center',
      borderRadius: 10,
      marginVertical: 10,
    },
    category: {
      width: '80%',
      color: '#000000',
      backgroundColor: '#CCCCCC',
      fontSize: 24,
      textAlign: 'center',
      borderRadius: 10,
      marginVertical: 10,
    },
  },
  image: {
    width: 118,
    height: 118,
  }
});