import React, { useEffect, useState } from 'react';
import { Image, ImageBackground, Pressable, StyleSheet, Text, View } from 'react-native';
import {loadAsync} from 'expo-font';

const MainImg = ({navigation}) => {
  const searchPage = () => {
    navigation.push('Search');
  };
  const [fontsLoaded, setFontsLoaded] = useState(false);
  useEffect(() => {
    const getFonts = async() => {
      await loadAsync({
        'SourceHanSerifK-VF' : require('../../assets/fonts/SourceHanSerifK-VF.otf'),
      });
      setFontsLoaded(true);
    }
    if (!fontsLoaded) {
      getFonts();
    }
  }, []);
  return fontsLoaded ? (
    <>
      <ImageBackground
        source={require('../../assets/img/main.png')}
        style={styles.container}>
        <View style={styles.container.topItem}>
          <Text style={styles.container.topItem.logo}>AREUM</Text>
          <Pressable onPress={searchPage}>
            <Image
              source={require('../../assets/img/search.png')}
              style={styles.container.topItem.searchButton}
            />
          </Pressable>
        </View>
        <View style={styles.container.container}>
          <View style={styles.container.container.slogan}>
            <Text style={styles.container.container.slogan.title}>
              나만의 개성과 스타일
            </Text>
            <Text style={styles.container.container.slogan.title2}>
              4050&nbsp;
              <Text style={styles.container.container.slogan.title3}>
                FASHION & BEAUTY
              </Text>
            </Text>
          </View>
        </View>
      </ImageBackground>
    </>
  ) : <></>;
};

export default MainImg;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 390,
    flexDirection: 'column',
    justifyContent: 'space-between',
    // backgroundColor: '#FFFFFF',
    topItem: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      width: 334,
      height: 29,
      marginTop: 43,
      marginLeft: 22,
      logo: {
        fontSize: 20,
        fontWeight: '800',
        color: '#FFFFFF',
        fontFamily: 'SourceHanSerifK-VF',
      },
      searchButton: {
        width: 16,
        height: 16,
      },
    },
    container: {
      width: '100%',
      height: 82,
      backgroundColor: 'rgba(40,40,40,0.4)',
      slogan: {
        width: 334.14,
        height: 56.8,
        flexDirection: 'column',
        justifyContent: 'space-around',
        marginTop: 13,
        marginLeft: 22,
        title: {
          height: 27.55,
          color: '#FFFFFF',
          fontSize: 20,
          fontWeight: '700',
          fontFamily: 'SourceHanSerifK-VF',
        },
        title2: {
          height: 27.55,
          color: '#FFFFFF',
          fontSize: 20,
          fontWeight: '800',
          fontFamily: 'SourceHanSerifK-VF',
        },
        title3: {
          height: 27.55,
          color: '#FFFFFF',
          fontSize: 20,
          fontWeight: '700',
          fontFamily: 'SourceHanSerifK-VF',
        },
      },
    },
  },
});
