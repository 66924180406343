import React from 'react';
import {useState} from 'react';
import {JSEncrypt} from 'jsencrypt';
import {
  SafeAreaView,
  Text,
  View,
  TextInput,
  StyleSheet,
  Button,
} from 'react-native';
import {login, verify_token} from '../api/useLogin';
// import {PUBLIC_KEY} from '@env';
import AsyncStorage from '@react-native-async-storage/async-storage';

// const public_key = PUBLIC_KEY.replace(/\\n/g, '\n');
const public_key = '-----BEGIN RSA PUBLIC KEY-----\nMIICCgKCAgEA3WJw0p3yrfa5FKYXdTAMOYEopj/QVuUTNbbE6+LF7P6XDJkplZjB\nhx4H6dY9sOtvH1h28vHixhtqrSL+o2mH9HJdZBIQatmbmr1iSV3aIm4lHZOeDb1J\nXLxjiwS7x72BQVydT3JornHkZOn2G6zV/1X0Tbd3N/2kBgTabt97uPLw/IzRyge5\nDEZ4b7jZ33h8hxx1isCwzO+hHtA3IqbMTKbXDgGHHkHjqzNPLTp0JvK8Xwab71rP\nHlZCAdpmKZH2FbfbNDZ93vPWJO7OhUFuSTJfM4fzeA/Tj6P5M/YRHw2ugt13NemR\nzqtUnDWlnR9o3SKcJWDhJQ3V6Eef9VCjSWx04DtcUxptZ82fsmFw4YyduuM+j0Oh\njtNE6QdFQXW2aazuOPjUFxwsDw3unrXX8OQN3ZQCfAZnKUhN3xZLWjaUSn+Bz2RU\nVEGZqmUphsnOxkH8U2yjCqEMK4std0VI+QuHE1uuDhdxoozVicBrljZr56PUpK4+\nFPGjt2OUeY4YOxscv3gbuOAc0gfKsR0LoeEsmqenjpErd0gQ19NHKmAyKfJYMTSR\n2ghrf9zeTPRmEgrP3vtE+K7xEBb2fbLEKYIN/uikl94Og0VZs0Ho6R3zT7VFbi/I\nsE9HFDxKNJ4PUZu/jOwBljCfDx5ldbJRN/Q5dkEQdhjWffp3TOiYcE0CAwEAAQ==\n-----END RSA PUBLIC KEY-----'
const encrypt = new JSEncrypt();
encrypt.setPublicKey(public_key);

const Login = ({navigation}) => {
  const [userid, setUserid] = useState('');
  const [password, setPassword] = useState('');
  const loginChk = async () => {
    const getToken = await AsyncStorage.getItem('userToken');
    console.log(getToken);
    if (!userid) {
      alert('아이디를 입력해주세요.');
      return;
    }
    if (!password) {
      alert('비밀번호를 입력해주세요.');
      return;
    }
    const encrypted = encrypt.encrypt(password);
    const [loginChk, isAdmin, token] = await login(userid, encrypted);
    await AsyncStorage.setItem('userToken', token);
    const getToken2 = await AsyncStorage.getItem('userToken');
    console.log(getToken2);
    navigation.pop();
  };

  return (
    <SafeAreaView style={styles.container}>
      <Text style={styles.text}>아이디</Text>
      <TextInput
        placeholder="아이디를 입력해주세요."
        style={styles.input.title}
        maxLength={20}
        value={userid}
        onChangeText={e => setUserid(e)}
        placeholderTextColor="#FFFFFF"
        autoCapitalize="none"
        autoCorrect={false}
      />
      <Text style={styles.text}>비밀번호</Text>
      <TextInput
        placeholder="비밀번호를 입력해주세요."
        style={styles.input.title}
        maxLength={20}
        value={password}
        onChangeText={e => setPassword(e)}
        placeholderTextColor="#FFFFFF"
        secureTextEntry={true}
        autoCapitalize="none"
      />
      <Button title="등록" onPress={loginChk} />
    </SafeAreaView>
  );
};

export default Login;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: '#000000',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    color: '#FFFFFF',
    fontSize: 24,
  },
  input: {
    title: {
      width: '80%',
      color: '#000000',
      backgroundColor: '#CCCCCC',
      fontSize: 24,
      textAlign: 'center',
      borderRadius: 10,
      marginVertical: 10,
    },
    content: {
      width: '80%',
      height: '50%',
      color: '#000000',
      backgroundColor: '#CCCCCC',
      fontSize: 24,
      textAlign: 'center',
      borderRadius: 10,
      marginVertical: 10,
    },
    age: {
      width: '80%',
      color: '#000000',
      backgroundColor: '#CCCCCC',
      fontSize: 24,
      textAlign: 'center',
      borderRadius: 10,
      marginVertical: 10,
    },
    category: {
      width: '80%',
      color: '#000000',
      backgroundColor: '#CCCCCC',
      fontSize: 24,
      textAlign: 'center',
      borderRadius: 10,
      marginVertical: 10,
    },
  },
});
